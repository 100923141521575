import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Img from 'gatsby-image'
import SEO from "./../../components/seo"

// s4n
import classNames from 'classnames';
// import "./../../assets/sass/templates/node/_blog.scss"
import { injectIntl } from "gatsby-plugin-intl"
import { useParseInlineImages } from '../../hooks/drupal/parseInlineImages'



const ComponentStateless = (props) => {
  const { data, intl } = props;
  const node = data.node;
  // const t = intl.formatMessage;

  // prepare/extract data
  let img = node?.relationships?.field_image_media?.relationships?.field_media_image?.localFile?.childImageSharp?.fluid;
  //let nodeBodyField = node.body ? node.body.value : null;

  // You can use the react-html-parser in case you don't want to use dangerouslySetInnerHTML attribute
  // const nodeBodyField = useParseInlineImages(node.body ? node.body.value : '', intl)
  const nodeBodyField = useParseInlineImages(node, intl)

  const colClass = classNames({
    'col-xs-12': true,
    'col-md-8': img,
  });

  return (
    <Layout node={node}>

        <SEO 
          title={node.title} 
          _keywords={``} 
          nodeId={node.drupal_internal__nid} 
          field_metatag={node.field_metatag}
        />

        <section id="node-person" className="node-person">
          <div className="container">
            {/* <small><em>{ node.created }</em></small> */}
            <div className="row">
                {img && <div className="col-xs-12 col-md-4">
                    <div style={{ maxWidth: `900px`, marginBottom: `1.45rem`, width: `100%` }}>
                        <Img fluid={ img } />
                    </div>
                </div>}
                <div className={colClass}>
                    <h1>{ node.title }</h1>
                    { nodeBodyField }
                </div>
            </div>
          </div>
        </section>

    </Layout>
  )
}

export default injectIntl(ComponentStateless)

// this query is Promie, we export it to let Gatsby fill variables from context from gatsby-node.js (id )
// and pass back to this file as props to below component
export const query = graphql`
    query(
      $id: String!,
      $drupal_id: String!,
      $langcode: String!
    ) {
    node: nodePerson(
      id: {
        eq: $id
      },
      drupal_id: {
        eq: $drupal_id
      },
      langcode: {
        eq: $langcode
      }
    ) {

      id
      drupal_id
      drupal_internal__nid
      internal {
          type
      }

      title
      body {
        summary
        value
      }
      created

      field_metatag {
          # basic
              title
              description
              abstract
              keywords
          # advanced
              # canonical_url
              # cache_control
      }

      relationships {

        # field_image {
        #   localFile {
        #     childImageSharp {
        #       fluid(maxWidth: 400) {
                    # # ...GatsbyImageSharpFluid
                    # # @see: https://www.gatsbyjs.org/packages/gatsby-image/
                    # ...GatsbyImageSharpFluid_withWebp
        #       }
        #     }
        #   }
        # }

        # @see: https://stackoverflow.com/questions/55032900/gatsby-drupal-8-getting-an-image-when-using-the-media-module-acquia-lightni
        field_image_media {
            relationships {
                field_media_image {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 400) {
                                # ...GatsbyImageSharpFluid
                                # @see: https://www.gatsbyjs.org/packages/gatsby-image/
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }

      }

    }
  }
`
